import React from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import logo from "../images/space.jpg"

import {
  Navbar,
  NavbarBrand,
 } from 'reactstrap';


import 'bootstrap/dist/css/bootstrap.css'
import './header.css'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let brandName

    if (location.pathname.startsWith("/article")) {
      brandName = "paul.querna.org: Paul's Journal"
    } else {
      brandName = "paul.querna.org"
    }

    return (
      <div>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-12 navheader">
                <Navbar dark expand="md">
                  <NavbarBrand href="/" className="navbrand">{brandName}</NavbarBrand>
                </Navbar>
              </div>
            </div>
              <div className="row">
                <div className="col-md-12 headerimg">
                  <Link to={'/'} ><img src={logo} width="100%" className="navimage"/></Link>
                </div>
              </div>
          </div>
        </header>

       <main role="main">
          <div className="container">
            <div className="row">
              <div className="col-md-12 content">
                {children}
              </div>
            </div>
          </div>
       </main>

       <footer className="text-muted site-footer">
          <div className="container">
            <p className="float-right">
              &copy; Paul Querna
            </p>
          </div>
       </footer>
      </div>
    )
  }
}

Template.propTypes = {
  children: PropTypes.func,
  location: PropTypes.object,
  route: PropTypes.object,
}

export default Template
