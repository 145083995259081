import React from 'react'

import Img from "gatsby-image";

import profilePic from './profile-pic.jpg'

class Bio extends React.Component {
  render() {
    return (
      <p>
        Written by <strong>Paul Querna</strong>, CTO @ ScaleFT.{' '}
        <a href="https://twitter.com/pquerna">@pquerna</a>
      </p>
    )
  }
}

export default Bio
